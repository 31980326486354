@mixin tabs() {
    .toggle-tabs {
        display: block;
        width: 100%;
        position: relative;
        border-bottom: 1px solid lightgray;
        li {
            float: left;
            @include transition-property(all);
            @include transition-duration(300ms);
            margin-bottom: -5px;
            transition: all .25s;
            padding-bottom: 4px;
            &:hover {
                border-color: lightgray;
            }

            >span {
                @include not-selectable;
                display: inline-block;
                padding: 0;
                height: 50px;
                line-height: 50px;
                padding: 0 20px;
                cursor: pointer;
                text-transform: none;
                font-size: 18px;
                position: relative;
                color: gray;
                font-weight: bold;
                text-transform: uppercase;
                letter-spacing: 0.5px;
            }
        }

        li.current {
            background: $primary-color;
            padding-bottom: 4px;
            margin-bottom: 0px;
            >span {
                background: white;
                z-index: 5;
                padding: 0 20px;
                color: $c-gray;
            }
        }
        li.current span{
            color: $primary-color;
        }

        li:first-child {
            border-left: none;
        }

        &:after {
            @include clearfix;
        }
    }

    >dl {
        width: 100%;

        >dt {
            display: none;
        }

        >dd {
            width: 100%;
            display: none;
            padding: 25px 0;
            border-top: 0;
        }

        >dd.current {
            display: block;
        }
    }
}

@mixin accordionDtBase($arrow-direction: 'down', $border-color: $c-module-border) {
    font-size: 1.5rem;
    color: $c-gray;
    @include not-selectable;
    font-weight: 700;
    padding: 12px 0 12px 0;
    border: none;
    position: relative;
    background: none;
    &:hover {
        background-color: $c-module-background-hover;
    }
}

@mixin accordion() {
    .toggle-tabs {
        display: none;
    }

    &.accordion-open {
        dl {
            dd {
                @include bp(min-width, $xs-bootstrap + 1px) {
                    &.current {
                        display: inline-block;
                    }
                }
            }
        }
        >dl{
            >dt.current{
                @include bp(min-width, $xs-bootstrap + 1px) {
                    &:after {
                        transform: rotate(180deg);
                    }
                } 
            }
        }
    }

    >dl {
        >dt {
            @include accordionDtBase;
            font-size: 17px;
            display: block;
            width: 100%;
            letter-spacing: 1px;
            @include bp(min-width, $xs-bootstrap + 1px) {
                display: block;
                border-bottom: 1px solid $bg-color;
                &:after {
                    display: block !important;
                    font-family: "simple-line-icons";
                    font-weight: 900;
                    content: "\e604" !important;
                    font-size: 0.8rem;
                    position: absolute;
                    top: 19px;
                    right: 5px;
                    left: auto;
                    border: none;
                    color: gray;
                    transition: all .5s ease;
                    transform: rotate(0deg);
                }
            }
            @include bp(max-width, $xs-bootstrap) {
                padding: 0;
                font-size: 1.5rem;
                margin-bottom: 5px;
            }
        }

        >dd {
            margin: 0;

            @include bp(min-width, $xs-bootstrap + 1px) {
                display: none;
            }
        }

        >dd:last-child {
            border-width: 0 1px 1px 1px;
        }
    }
}

@mixin accordionCollapse() {
    >dl {
        >dt {
            cursor: pointer;
            border-bottom-width: 0;
            font-size: 15px;
            padding: 10px 5px;
            border-radius: none;
            text-transform: uppercase;
            &:hover {
                color: $c-action;
            }

            &:after {
                position: absolute;
                font-family: "simple-line-icons";
                font-weight: 900;
                content: "\e604" !important;
                right: 5px;
                top: 25%;
                transform: translateY(-50%);
                margin-top: 0;
                font-size: 13px; 
                color: lightgray;  
                transition: all .5s ease;
                transform: rotate(0deg);
            }

            
        }

        >dd {
            display: none;
        }
    }

    &.accordion-open {
        #narrow-by-list2,
        #narrow-by-list {
            dt {
                &.current {
                    border-bottom-width: 0;
                }

                &.current:after {
                    position: absolute;
                    font-family: "simple-line-icons";
                    font-weight: 900;
                    right: 5px;
                    top:25%;
                    transform: translateY(-50%);
                    margin-top: 0;
                    font-size: 13px;
                    color: lightgray;
                    transform: rotate(180deg);
                }
            }

            dd.current {
                display: block;
                padding: 0;
            }
        }
        .collateral-tabs{
            .current{
                display: block;
            }
        }
    }
}

.product-view .product-collateral.accordion-open .collateral-tabs .tab.current:after {
    -webkit-transform: rotate(0deg);
    -ms-transform: rotate(0deg);
    transform: rotate(0deg);
}

.product-view .product-collateral .collateral-tabs .tab:after {
    right: 15px;
    -webkit-transition: all .2s ease;
    transition: all .2s ease;
    -webkit-transform: rotate(-90deg);
    -ms-transform: rotate(-90deg);
    transform: rotate(-90deg);
    top: 15px;
}
