// =============================================
// Mixin - Typography
// =============================================

@mixin h1 {
    margin: 0;
    margin-bottom: 0.7em;
    color: $c-h1;
    font-family: $f-stack-sans;
    font-size: 28px;
    font-weight: 400;
    font-style: normal;
    line-height: 1.2;
    text-rendering: optimizeLegibility;
    text-transform: uppercase;
}

@mixin h2 {
    margin: 0;
    margin-bottom: 1rem;
    color: $c-h2;
    font-family: $f-stack-sans;
    font-size: 24px;
    font-weight: 400;
    font-style: normal;
    line-height: 1.2;
    text-rendering: optimizeLegibility;
    text-transform: uppercase;
}

@mixin h3 {
    margin: 0;
    margin-bottom: 10px;
    color: $c-h3;
    font-family: $f-stack-sans;
    font-size: 18px;
    font-weight: 400;
    font-style: normal;
    line-height: 1.4;
    text-rendering: optimizeSpeed;
    text-transform: uppercase;
}

@mixin h4 {
    margin: 0;
    margin-bottom: 10px;
    color: $c-h4;
    font-family: $f-stack-sans;
    font-size: 14px;
    font-weight: bold;
    font-style: normal;
    line-height: 1.4;
    text-rendering: optimizeSpeed;
    text-transform: uppercase;
}

@mixin h5 {
    margin: 0;
    margin-bottom: 10px;
    color: $c-h5;
    font-family: $f-stack-sans;
    font-size: 13px;
    font-weight: bold;
    font-style: normal;
    line-height: 1.4;
    text-rendering: optimizeSpeed;
    text-transform: uppercase;
}

@mixin h6 {
    margin: 0;
    margin-bottom: 5px;
    color: $c-h6;
    font-family: $f-stack-sans;
    font-size: 12px;
    font-weight: bold;
    font-style: normal;
    line-height: 1.4;
    text-rendering: optimizeSpeed;
    text-transform: uppercase;
}

@mixin pageTitle() {
    text-transform: uppercase;
    font-size: 30px;
    font-weight: 600;
    color: $c-gray;
    padding-bottom: 3px;
    margin-bottom: 15px;
    text-transform: none;
    font-family: $f-stack-sans;
}

@mixin single-text-shadow($param1, $param2, $param3, $param4, $param5) {
    text-shadow: $param1, $param2, $param3, $param4, $param5;
}

@mixin opacity($param1) {
    opacity: $param1;
}

@mixin box-sizing($param1) {
    box-sizing: $param1;
}

@mixin appearance($param1) {
    appearance: $param1;
}

@mixin border-radius($param1) {
    border-radius: $param1;
}

@mixin inline-block() {
    display: inline-block;
}

@mixin single-transition($param1, $param2) {
    transition: $param1 $param2;
}

@mixin transition-property($param1) {
    transition-property: $param1;
}

@mixin transition-duration($param1) {
    transition-duration: $param1;
}
@mixin inputTransparentBordered($fontSize) {
    font-family: $f-stack-sans;
    font-size: $fontSize;
    font-weight: 300;
    background: none;
    color: white;
    border: 1px solid white;
    border-radius: 0;
    outline: none;
    &::placeholder {
        color: white;
        font-family: $f-stack-sans;
        font-size: $fontSize;
        font-weight: 300;
    }

    &:-ms-input-placeholder {
        color: white;
        font-family: $f-stack-sans;
        font-size: $fontSize;
        font-weight: 300;
    }

    &::-ms-input-placeholder {
        color: white;
        font-family: $f-stack-sans;
        font-size: $fontSize;
        font-weight: 300;
    }
}
@mixin buttonXL(){
    min-height: 64px;
    line-height: 64px;
    font-size: $f-size-mid + 2px;
    font-weight: 400;
    padding: 0px 30px;
    float: left;
    min-width: 160px;
    width: 100%;
    white-space: normal;
    text-transform: none;
    font-family: $f-stack-sans;
    margin-bottom: 0;
    @include bp(max-width, $xs-bootstrap){
        width: 100%;
        max-width: 100%;
    }
}