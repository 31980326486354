// =============================================
// Mixin - Breakpoint
// =============================================

$mq-support: true !default;
$mq-fixed-value: 1024px !default;

@mixin bp($feature, $value) {
    // Set global device param
    $media: only screen;

    // Media queries supported
    @if $mq-support == true {

        @media #{$media} and ($feature: $value) {
            @content;
        }

        // Media queries not supported
    } @else {

        @if $feature == 'min-width' {
            @if $value <= $mq-fixed-value {
                @content;
            }
        } @else if $feature == 'max-width' {
            @if $value >= $mq-fixed-value {
                @content;
            }
        }

    }
}

/*
// ----------------------------------------------
// Usage example:
// For IE set $mq-support to false.
// Set the fixed value.
// Then use mixins to test whether styles should be applied.
// ----------------------------------------------

$mq-support: false;
$mq-fixed-value: 1024;

// Renders at fixed value
@include bp (min-width, 300px) { 
    div { color:#000; }
}

// Doesn't render without MQ support
@include bp (min-width, 1200px) { 
    div { color:#FFF; }
}

// Doesn't render without MQ support
@include bp (max-width, 300px) { 
    div { color:#444; }
}

// Renders at fixed value
@include bp (max-width, 1200px) { 
    div { color:#888; }
}

// ----------------------------------------------
*/

