// Backgrounds Gradient
$primaryGradient: #2C2C2C;

// =============================================
// Compass Defaults
// =============================================
// Border Radius
$default-border-radius: 0px;

// Transition

$default-transition-property: all;
$default-transition-duration: 200ms;
$default-transition-function: linear;
$default-transition-delay: 0;

// =============================================
// Primary Break Points
// =============================================

// These should be used with the bp (max-width, xx) mixin
// where a min-width is used, remember to +1 to break correctly.
// If these are changed, they must also be updated in app.js

$bp-xxsmall: 320px;
$bp-xsmall: 479px;
$bp-small: 599px;
$bp-medium: 770px;
$xs-bootstrap: 768px;
$bp-large: 979px;
$bp-xlarge: 1199px;
$bp-xxlarge: 1400px;

// =============================================
// Base Values
// =============================================

// Spacing

$gap: 15px;

$trim: 15px;
// This will get applied on viewports smaller than 480px
$trim-small: 15px;

// Padding that will get applied to content areas
$box-spacing-large: 20px;

// Standard padding around box elements such as banner messages, etc
$box-spacing: 10px;

// Standard spacing between elements within a box, such as "Add to cart", "Price box", etc
$element-spacing: 7px;

// Dimensions

$max-content-width: 1250px;
$max-container-width: $trim+$max-content-width+$trim;
$max-std-formatted-width: 50em;

// =============================================
// Brand Colors
// =============================================

// Usually not used directly in Sass partials.
// Only used to define context-based color vars in this file.

$c-blue: #3e5ee6;
$c-green: #1C9D0A;
$c-pink: #D85378;
$c-orange: #F3793B;
$c-red: #2C2C2C;
$c-yellow: #FFDD15;
$c-black: #000000;
$c-gray: #454545;
$c-footer: #1E1E1E;

// =============================================
// Base Colors
// =============================================

// Text

$c-text: #191919;
$c-text-gray: #A0A0A0;
$c-text-white: #E6E6E6;
// Primary font color for headings and other non-link text
$c-text-primary: $c-text;


// Interaction

$c-action: $c-red;
$c-stimulus: darken($c-blue, 15%);
$c-subtle: #A0A0A0;

// Notifications

$c-danger: #EF4238;
$c-danger-background: #FAEBE7;

$c-success: $c-green;
$c-success-background: #EFF5EA;

$c-warn: #E26703;
$c-warn-background: #F9EBE6;

$c-dark: #676157;

// Global Elements

$c-divider-border: #ECECEC;

// =============================================
// Element Colors
// =============================================

// Buttons

$c-button: #1475BC;
$c-button-hover: #062A3F;
$c-button-active: #062A3F;

// Secondary Buttons

$c-button-secondary: #DDDDDD;
$c-button-secondary-hover: darken(#DDDDDD, 5%);
$c-button-secondary-active: darken(#DDDDDD, 10%);

$c-button-subtle: #ECECEC;

// Forms

$c-input-border: #C0C0C0;
$c-input-border-focus: $c-blue;

$c-input-placeholder: $c-text-gray;
$c-input-text: $c-text;

$c-fieldset-border: #CCCCCC;
$c-fieldset-border-light: #EDEDED;

$c-title-border: #D8D8D8;

// Headings

$c-h1: $c-blue;
$c-h2: $c-text;
$c-h3: $c-text;
$c-h4: $c-text;
$c-h5: $c-text;
$c-h6: $c-text;

// Link
$c-link: $c-action;
$c-link-hover: darken($c-action, 5%);
$c-link-focus: $c-action;
$c-link-active: darken($c-action, (5% / 2));

// Module (generic)

$c-module-background: #F4F4F4;
$c-module-background-hover: shade($c-module-background, 3%);
$c-module-background-secondary: #EAF8FF;
$c-module-background-dark: #AAAAAA;
// This should be applied whenever the border is surrounding a white element and/or is directly showing on white.
$c-module-border: #CCCCCC;
// This should be applied whenever the border is wrapping an element with $c-module-background
$c-module-border-light: #DFDFDF;
$c-module-border-dark: #777777;
$c-module-border-highlight: $c-red;


// Table

$c-table-background: lightgray;
$c-table-border: #C0C0C0;

$c-table-zebra-odd: #F8F7F5;
$c-table-zebra-even: #EEEDED;

// Tabs

$c-tabs-background: #F0F0F0;

// Email
$c-email-background: #EBEBEB;
$c-email-background-secondary: #E1F0F8;
$c-email-border: #C3CED4;
$c-email-action: #3696C2;
$c-email-cell: #F1F1F1;
$c-email-highlighted-background: #FFFDD9;
$c-email-highlighted-border: #FFF74C;

// =============================================
// Typography
// =============================================

// Google Web Font stylesheet is included via layout XML

// Font Stacks

$f-stack-sans: 'Lato',
sans-serif;
$f-helvetica-sans: 'Helvetica Neue',
Verdana,
Arial,
sans-serif;
$f-stack-serif: Georgia,
Times,
'Times New Roman',
serif;
$f-stack-special: 'Raleway',
'Helvetica Neue',
Verdana,
Arial,
sans-serif;

$f-stack-default: $f-stack-sans;

// Font Sizes

$f-size-xxxl: 24px;
$f-size-mid: 20px;
$f-size-xxl: 18px;
$f-size-xl: 16px;
$f-size-l: 15px;
$f-size-m: 14px;

$f-size: 14px;

$f-size-s: 13px;
$f-size-xs: 12px;
$f-size-xxs: 11px;

// Base Measures

$b-line-height: 1.5;
$b-margin-bottom: 1.5em;

$activeHeader: #ca2e25;
$borderHeader: #D8D8D8;
$primaryHeader: #a7160d;
$backgroundGray: #EEEEEE;

$primary-color: #1475BC;
$primary-color-dark: #062A3F;
$primary-color-light: #4DB4D7;
$secondary-color: #48BF91;
$third-color: #191919;
$title-color: $primary-color;
$bg-color: #EDEDED;

$header-color: white; 
$gradient-bg: $primary-color;
$gradient-first-color: $primary-color-light;
$gradient-secondary-color:$primary-color-light;