/* ============================================ *
 * Homepage
 * ============================================ */

/* -------------------------------------------- *
 * Primary Banner
 */

body.cms-home {
    .main-container {
        padding-top: 0;
    }
    .slideshow-container {
        margin-top: 0;
    }
}

.slideshow .banner-msg {
    position: absolute;
    text-align: center;
    text-transform: uppercase;
    width: 100%;
    top: 30%;
    @include not-selectable;
}

.slideshow .banner-msg h2 {
    color: #FFFFFF;
    font-size: 24px;
    @include single-text-shadow(1px, 1px, 3px, false, #555555);
}

.slideshow .banner-msg h2 strong {
    font-weight: bold;
    display: block;
    font-size: 36px;
}

@include bp(max-width, $bp-medium) {

    .slideshow .banner-msg h2 {
        font-size: 20px;
    }

    .slideshow .banner-msg h2 strong {
        font-size: 24px;
    }
}

@include bp(max-width, $bp-xsmall) {

    .slideshow .banner-msg {
        top: 20%;
    }

    .slideshow .banner-msg h2 {
        font-size: 14px;
    }

    .slideshow .banner-msg h2 strong {
        font-size: 16px;
    }
}

/* -------------------------------------------- *
 * Promotion Banner Section
 */

.promos {
    margin: 0 0 $gap 0;
    padding: 0;
    width: 100%;
}

.promos:after {
    @include clearfix;
}

/* Specifying the body only in order to override the .std ul li styling */

body .promos>li {
    margin: 0 0 10px 0;
    list-style: none;
    text-align: center;
    position: relative;
    border: 1px solid $c-module-border;
    @include not-selectable;
}

.promos>li:last-child {
    margin-bottom: 0;
}

.promos img {
    max-width: 100%;
    width: 100%;
}

.promos a:hover {
    @include opacity(0.8);
    display: block;
}

.promos span {
    color: #FFFFFF;
    text-transform: uppercase;
    position: absolute;
    display: block;
    width: 100%;
    top: 10%;
    font-weight: 500;
    font-size: 20px;
    font-family: $f-stack-special;
    @include single-text-shadow(1px, 1px, 3px, false, #555555);
}

.promos strong {
    font-weight: 600;
    font-size: 26px;
    display: block;
}

@include bp(min-width, $bp-medium + 1) {

    .promos span {
        font-size: 16px;
    }

    .promos strong {
        font-size: 17px;
    }

    /* Config: Three columns + flexible gutter */
    // Container: 480 - (2 * 30 trim wide) = 420
    // Math: (420 - (2 * 10 gutter)) / 3 items = 133.333333333
    body .promos>li {
        float: left;
        width: percentage(133.333333333 / 420);
        margin-right: percentage(10 / 420);
    }

    .promos>li:nth-child(3n) {
        margin-right: 0;
    }
}

@include bp(min-width, 880px) {
    .promos span {
        font-size: 18px;
    }

    .promos strong {
        font-size: 24px;
    }
}

/* -------------------------------------------- *
 * New Products Section
 */

.cms-index-index .products-grid .ratings,
.cms-index-index .products-grid .actions,
.cms-index-noroute .products-grid .ratings,
.cms-index-noroute .products-grid .actions {
}

.cms-index-index h2.subtitle {
    padding: 6px 0;
    text-align: center;
    color: $c-text-primary;
    font-weight: 600;
    border-bottom: 1px solid $c-module-border;
    border-top: 1px solid $c-module-border;
}

.cms-index-noroute h2.subtitle {
    display: none;
}

.owl-dots {
    text-align: center;
}

.owl-dots button.owl-dot {
    width: 15px;
    height: 15px;
    border-radius: 50%;
    display: inline-block;
    background: $primary-color-light;
    opacity: .5;
    margin: 0 3px;
    &:focus {
        outline: none;
    }
}

.owl-dots button.owl-dot.active {
    background-color: $primary-color;
    opacity: 1;
}

.cms-home{
    .success-msg,.error-msg{
        position: fixed;
        top: 0;
        left: 0;
        z-index: 10000;
        width: 100%;
        background: transparent;
        li{
            border-left: none;
            border-radius: 0 0 10px 10px;
            border: none;
            color: white !important;
            text-align: center;
            font-weight: 300;
            box-shadow: 0px 0px 25px 0px rgba(80, 80, 80, 0.3);
            height: 60px;
            @include bp(min-width, $bp-small+1){
                height: 40px;
            }
            br{
                display: none;
            }
        }
    }
    .success-msg li{
        background: $c-green;
        background-color: $c-green !important;
    }
    .error-msg li{
        background: $c-red;
        background-color: $c-red !important;
    }
}

.title{
    h2{
        font-weight: bold;
        letter-spacing: 0.5px;
        font-size: 18px;
    }
}

.banners-info{
    img{
        border: 1px solid #EEEEEE;
    }
}

.modal-backdrop{
	z-index: 2000;
}

.pop-up-omv{
	z-index: 2050 !important;
}

.pop-up-omv .modal-dialog{
	top: 45%;
    transform: translateY(-50%) !important;
}

.pop-up-omv .modal-content{
	box-shadow: none;
	border-radius: 0px;
	border: none;
}

.pop-up-omv .modal-content .modal-body{
	padding: 0 15px;
}

.pop-up-omv .modal-content .modal-body img{
	width: 100%;
}

.pop-up-omv .modal-content .modal-body .col-lg-12{
	padding: 0;
}

.pop-up-omv .modal-content .modal-body button.close {
	position: absolute;
	right: -15px;
	top: -15px;
	color: white;
	opacity: unset;
	background: $primary-color;
	font-size: 25px;
	height: 40px;
	width: 40px;
	border-radius: 50px;
	font-weight: 300;
	padding-bottom: 1px;
}

@media only screen and (max-width : 768px) {
	.pop-up-omv .modal-content .modal-body button.close {
		right: 5px;
    	top: 5px;
	}
	.pop-up-omv .modal-dialog{
		top: 45%;		
	}
	.pop-up-omv .modal-content .modal-body button.close {
		height: 30px;
		width: 30px;
		padding-bottom: 3px;
	}
}

.nav-tabs{
    background: white;
    padding: 0;
    border-bottom: none !important;
    .navbar-toggler{
        width: 50%;
        background: $primary-color;
        color: white;
        height: 40px;
        border-radius: 0;
        transition: all .3s;
        .navbar-text{
            color: white;
            font-size: 1.4rem;
        }
        
        .navbar-toggler-icon{
            background-image:  none;
        }
        &:focus{
            outline: none;
        }
    }
    .navbar-nav {
        @include bp(min-width, $xs-bootstrap+1){
            width: 100%;
            .nav-link{
                height: 40px;
                line-height: 40px;
                padding: 0 15px;
                margin: 0 15px;
                background-color: $bg-color;
                border-radius: 5px;
                &.active{
                    color: white;
                }
            }
        }
    }
    .navbar-collapse{
        @include bp(max-width, $xs-bootstrap){
            width: 50%;
            position: absolute;
            z-index: 2;
            background: white;
            top: 30px;
            left: 50%;
            transform: translateX(-50%);
            border-radius: 0 0 6px 6px;
            margin-top: 10px;
            text-align: center;
            padding: 10px;
            border: 1px solid white;
            transition: all .3s;
            opacity: 0;
            &.show {
                opacity: 1;
                border: 1px solid lightgray;
            }
            .nav-link{
                margin-bottom: 5px;
                &.active{
                    background: white;
                }
                &:last-child{
                    margin-bottom: 0px;
                }
            }
        }
    }
}

#rev_slider_2_wrapper{
    @include bp(min-width, $bp-xsmall+1){
        display: none !important;
    }
}

.forcefullwidth_wrapper_tp_banner,
#rev_slider_1_wrapper{
    display: none !important;
    @include bp(min-width, $bp-xsmall+1){
        display: block !important;
    }
}

/* breakpoint ipad 9.7" */

@media only screen 
  and (min-device-width: 768px) 
  and (max-device-width: 768px) 
  and (orientation: portrait) 
  and (-webkit-min-device-pixel-ratio: 2) 
  {
    .top-menu-left{
        display: block !important;
    }
}

.whatsapp_a i{
    font-family: "Font Awesome 5 Brands";
    margin-top: 14px;
    font-size: 31px;
}
